import { graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { ThreeDots } from "react-loader-spinner"
import Cards from "../components/Cards"
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import PageFooter from "../components/PageFooter"
import SiteMetadata from "../components/SiteMetadata"
import Testimonials from "../components/Testimonials"
import MyProcess from "../components/MyProcess"
import WhatDoIDo from "../components/WhatDoIDo"

import { filterByLocale } from "../helpers"
import Mentions from "../components/Mentions"
import Advantages from "../components/Advantages"

const IndexPage = ({ data }) => {
  const [cards, setCards] = useState([])

  const currentLocale = "pt-BR"

  useEffect(() => {
    const shuffledCards = filterByLocale(data.portfolio, currentLocale)
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
      .slice(0, 9)

    setCards(shuffledCards)
  }, [data.portfolio])

  const testimonials = filterByLocale(data.testimonial, currentLocale)

  return (
    <Layout>
      <SiteMetadata
        title="Pri Bellafronte - Designer de Marcas e logos"
        description="Pri Bellafronte é uma designer gráfico especializada em criação de marcas e logos que passem sentido e valor"
        image={data.socialCoverImage.publicURL}
      />

      <Hero />

      <div className="py-8 lg:py-12">
        {cards.length > 0 ? (
          <Cards items={cards} />
        ) : (
          <div className="container">
            Carregando projetos...
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}
      </div>

      <div className="py-8 flex justify-center">
        <MyProcess />
      </div>
      <div className="py-8 lg:py-12 site-color-black font-color-white">
        <Advantages />
      </div>
      <div className="py-8 flex justify-center">
        <Testimonials testimonials={testimonials} />
      </div>
      <div className="py-8 lg:py-12 site-color-black font-color-white">
        <Mentions />
      </div>
      <div className="py-8 px-4 lg:py-12 bg-site-white">
        <PageFooter />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    portfolio: allContentfulPortfolio(
      filter: { projectType: { glob: "Home page" } }
    ) {
      nodes {
        ...PortfolioCard
      }
    }
    testimonial: allContentfulTestimonial {
      nodes {
        company
        name
        node_locale
        title
        testimonial {
          testimonial
        }
        photo {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    whatDoIDoImage: file(relativePath: { eq: "education-r_t20_Rw3yGd-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          height: 600
          quality: 85
          layout: CONSTRAINED
        )
      }
    }
    socialCoverImage: file(
      relativePath: { eq: "bellafronte-studio-social-cover.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          height: 400
          quality: 90
          layout: CONSTRAINED
        )
      }
      publicURL
    }
  }
`
