import React from "react"

const Mentions = () => {
  return (
    <div className="container">
      <div className="flex flex-wrap -mx-3 lg:-mx-6">
        <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0 font-color-white">
          <p className="mb-1 text-3xl leading-normal font-semibold tracking-tight font-color-white">
            Mençoes
          </p>
          <p className="mb-8 text-l leading-normal tracking-tight">
            Abaixo alguns lugares onde fui mencionada 😊
          </p>
          <a
            href="https://www.designrush.com/best-designs/logo"
            className="text-blue-100 underline"
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-blue-300">DesignRush - Best Logo Designs</p>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Mentions
