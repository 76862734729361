import React from "react"

const Hero = () => (
  <div className="container py-4 lg:pb-2">
    <div className="w-full md:w-1/2 pt-4 pb-2 md:pb-0">
      <h2 className="text-l sm:text-xl font-extrabold leading-tight tracking-tight">
        Olá, me chamo Pri Bellafronte.
      </h2>
      <h2 className="text-2xl sm:text-3xl font-extrabold leading-tight tracking-tight">
        Sou designer com foco em projetos de identidade visual.
      </h2>
    </div>
  </div>
)

export default Hero
