import React from "react"

const WhatDoIDo = ({ whatDoIDoImage }) => {
  return (
    <div className="container">
      <div className="flex flex-wrap -mx-3 lg:-mx-6">
        <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0 font-color-dark-gray">
          <p className="mb-1 text-3xl leading-normal font-semibold tracking-tight font-color-dark-gray">
            Processo de desenvolvimento
          </p>
          <p className="mb-1 text-2xl leading-normal font-semibold tracking-tight font-color-dark-gray">
            Briefing
          </p>
          <p className="mb-8 text-l leading-normal tracking-tight">
            O objetivo é a coleta de informações pertinentes ao projeto,
            utilizando-se de um questionário com perguntas direcionadas.
          </p>

          <p className="mb-1 text-2xl leading-normal font-semibold tracking-tight font-color-dark-gray">
            Pesquisa
          </p>
          <p className="mb-8 text-l leading-normal tracking-tight">
            A pesquisa pode ser desenvolvida de diferentes formas, mas
            resumidamente trata-se de coletar informações sobre o mercado,
            concorrentes, público-alvo etc
          </p>
          <p className="mb-1 text-2xl leading-normal font-semibold tracking-tight font-color-dark-gray">
            Pré-apresentação
          </p>
          <p className="mb-8 text-l leading-normal tracking-tight">
            Etapa destinada ao alinhamento das ideias com o cliente, antes do
            resultado final. Nesta fase, o cliente deve analisar e passar seu
            feedback sobre a ideia proposta.
          </p>
          <p className="mb-1 text-2xl leading-normal font-semibold tracking-tight font-color-dark-gray">
            Criação
          </p>
          <p className="mb-8 text-l leading-normal tracking-tight">
            Após a aprovação do cliente na pré-apresentação, é feita a criação
            final do logotipo e dos demais itens orçados.
          </p>
          <p className="mb-1 text-2xl leading-normal font-semibold tracking-tight font-color-dark-gray">
            Apresentação final
          </p>
          <p className="mb-8 text-l leading-normal tracking-tight">
            Aqui é onde todo o processo de desenvolvimento é apresentado e
            explicado ao cliente, demonstrando o uso da marca, tipografia,
            escolha da paleta de cores etc
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhatDoIDo
