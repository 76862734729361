import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

import { Navigation, Pagination, Scrollbar } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

const Advantages = props => {
  const cardsData = [
    {
      title: "Diferenciação",
      content: `No mercado altamente competitivo de hoje, é essencial se destacar da concorrência. 
        Uma identidade visual forte pode ajudar a sua marca a se diferenciar, criando uma 
        imagem única e cativante`,
    },
    {
      title: "Reconhecimento",
      content: `Uma identidade visual bem elaborada, incluindo um logotipo, cores coerentes e um 
      estilo visual único, ajuda a sua marca a se destacar e ser facilmente reconhecida pelo público.`,
    },
    {
      title: "Credibilidade",
      content: `As empresas que investem em uma identidade visual coesa e de alta qualidade 
      demonstram que se preocupam com a forma como são percebidas pelo público.`,
    },
    {
      title: "Consistência",
      content: `Uma identidade visual bem definida proporciona consistência e coerência em todos 
      os seus materiais de marketing e comunicação.`,
    },
  ]

  return (
    <div className="container pb-6">
      <p className="text-l sm:text-xl font-extrabold leading-tight tracking-tight">
        Vantagens de investir em uma boa
      </p>
      <p className="text-2xl sm:text-3xl font-extrabold leading-tight tracking-tight mb-16">
        identidade visual:
      </p>

      <div className="grid grid-cols-1 gap-8 sm:grid-cols-4 sm:gap-4 lg:gap-8">
        {cardsData.map((card, index) => (
          <Card key={index} title={card.title} content={card.content} />
        ))}
      </div>
    </div>
  )
}

const Card = ({ title, content }) => {
  return (
    <div className="bg-white p-4 rounded shadow-md my-2 mr-8 flex-1 text-black sm:h-64 h-fit">
      <h2 className="text-lg font-semibold mb-2">{title}</h2>
      <p>{content}</p>
    </div>
  )
}

export default Advantages
