import React from "react"
import { FaCalendar, FaPen, FaLightbulb } from "react-icons/fa"

const MyProcess = () => {
  return (
    <div className="container pb-6">
      <p className="text-l sm:text-xl font-extrabold leading-tight tracking-tight">
        Como funciona meu processo
      </p>
      <p className="text-2xl sm:text-3xl font-extrabold leading-tight tracking-tight mb-16">
        para construir uma marca ideal para você:
      </p>
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-3 sm:gap-4 lg:gap-8">
        <div className="w-80 sm:h-80 h-fit">
          <p className="mb-4 text-l font-bold">
            1 - Coleta de informações através do briefing
          </p>
          <ul className="text-m list-disc list-inside">
            <li>Posicionamento da marca</li>
            <li>Cenário mercadológico</li>
            <li>Análise geral</li>
            <li>Propósito da marca</li>
            <li>Personalidade da marca</li>
            <li>Mapa mental</li>
            <li>Moodboard</li>
          </ul>
        </div>
        <div className="w-80 sm:h-80 h-fit">
          <p className="mb-4 text-l font-bold">
            2 - Pré apresentação da identidade visual
          </p>
          <ul className="text-m list-disc list-inside">
            <li>Paleta de cores</li>
            <li>Tipografia</li>
            <li>Criação de símbolos (se necessário)</li>
            <li>Criação de identidade visual</li>
            <li>Toolkit</li>
          </ul>
        </div>
        <div className="w-80 sm:h-80 h-fit">
          <p className="mb-4 text-l font-bold">3 - Apresentação final</p>
          <ul className="text-m list-disc list-inside">
            <li>Paleta de cores</li>
            <li>Tipografia</li>
            <li>Criação de símbolos (se necessário)</li>
            <li>Criação de identidade visual</li>
            <li>Toolkit</li>
            <li>Mockups</li>
            <li>Entrega dos arquivos finais</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MyProcess
